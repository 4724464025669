body {
  background-color: rgb(11, 28, 44);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.section-logger {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  gap: 10px;
  padding: 20px;
}

.section-logger .content-left {
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
}
.section-logger .content-left input {
  width: calc(100% - 160px);
  height: 35px;
  padding: 0 10px;
  border-radius: 6px;
  background-color: rgb(17 53 80);
  border: 1px solid rgb(9, 20, 28);
  color: rgba(255, 255, 255, 0.6);
}
.section-logger .content-left input::placeholder {
  color: rgba(255, 255, 255, 0.6);
}
*:focus-visible {
  outline: none;
}
.section-logger .content-left .title {
  width: 160px;
  color: rgb(255, 255, 255);
}

.section-logger-items {
  padding: 0 20px;
}
.btn-btn {
  width: 34px;
  height: 34px;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 34px;
  border: none;
  cursor: pointer;
}
.btn-red {
  background-color: #ff0000a6;
}

.empty-state {
  text-align: center;
   color: rgba(255, 255, 255, 0.6);;
}

.clear-button {
  width: 30px;
  height: 30px;
  position: fixed;
  right: 13px;
  top: 75px;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}